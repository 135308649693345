define("discourse/plugins/discourse-amazon-sns/initializers/message-react-native-app", ["exports", "discourse/lib/ajax", "discourse/lib/utilities"], function (_exports, _ajax, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "message-react-native-app",
    after: "inject-objects",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const caps = container.lookup("service:capabilities");
      if (caps.isAppWebview && currentUser) {
        (0, _utilities.postRNWebviewMessage)("authenticated", 1);
        let appEvents = container.lookup("service:app-events");
        appEvents.on("page:changed", () => {
          let badgeCount = currentUser.unread_notifications + currentUser.unread_high_priority_notifications;
          (0, _utilities.postRNWebviewMessage)("badgeCount", badgeCount);
        });
      }

      // called by webview
      window.SNS = {
        subscribeDeviceToken(token, platform, application_name) {
          (0, _ajax.ajax)("/amazon-sns/subscribe.json", {
            type: "POST",
            data: {
              token,
              platform,
              application_name
            }
          }).then(result => {
            (0, _utilities.postRNWebviewMessage)("subscribedToken", result);
          });
        },
        disableToken(token) {
          (0, _ajax.ajax)("/amazon-sns/disable.json", {
            type: "POST",
            data: {
              token
            }
          }).then(result => {
            (0, _utilities.postRNWebviewMessage)("disabledToken", result);
          });
        }
      };
    }
  };
});